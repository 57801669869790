<div class="container">
  <div class="row">
    <div class="col-12">
      <h2 class="ml-4">{{editorType === 'edit' ?
        ('CASE_CREDITOR.case_creditor_status.editor.heading_edit' | translate) :
        ('CASE_CREDITOR.case_creditor_status.editor.heading_create' | translate)
        }}</h2>
    </div>
  </div>

  <div *ngIf="isLoading" class="row">
    <div class="col-12 pt-5 text-center">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>
  </div>

  <div *ngIf="!isLoading" class="row">
    <div class="col-12">
      <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="card shadow mb-5">
          <div class="card-body">
            <div class="row">
              <app-input type="text" formControlName="name_en" appearance="standard" [fullWidth]="true" class="col-6"
                        [label]="'CASE_CREDITOR.case_creditor_status.editor.form.name_en' | translate"
              ></app-input>
              <app-input type="text" formControlName="name_es" appearance="standard" [fullWidth]="true" class="col-6"
                        [label]="'CASE_CREDITOR.case_creditor_status.editor.form.name_es' | translate"
              ></app-input>
            </div>
            <div class="row pt-5">
              <div class="col-md-4">
                <button mat-raised-button color="primary" class="ml-3" type="submit">
                  {{ this.editorType === 'edit' ?
                  ('CASE_CREDITOR.case_creditor_status.editor.heading_edit' | translate) :
                  ('CASE_CREDITOR.case_creditor_status.editor.heading_create' | translate) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
