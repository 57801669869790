<div class="container">
  <div class="row pb-3">
    <div class="col-12">
      <h2>{{"CASE_CREDITOR.case_creditor_status.list.heading" | translate}}</h2>
    </div>

    <div class="col-md-8">
      <button mat-raised-button color="primary" routerLink="create" type="button">
        + {{"CASE_CREDITOR.case_creditor_status.list.actions.create" | translate}}
      </button>
    </div>
    <div class="col-4 pt-md-0 pt-5">
      <app-input [formControl]="search" type="text" appearance="standard" fullWidth="true"
                [label]="translateService.instant('SHARED.search')" [prefixType]="'icon'" prefix="search">
      </app-input>
    </div>
  </div>

  <div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" style="width: 100%">
      <!-- Headers -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <!-- ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
      </ng-container>
      <!-- Name -->
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sor-header *matHeaderCellDef>{{"SHARED.model.name" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>
      <!-- Created at -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'SHARED.model.created_at' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.created_at | toDateObject | date }}  </td>
      </ng-container>

      <!-- Actions -->
      <ng-container matColumnDef="actions" class="mr-auto">
        <th mat-header-cell *matHeaderCellDef>{{"SHARED.actions" | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div style="display: flex">
            <p *ngFor="let action of actions" style="margin: 0; padding-right: 10px">
              <!--      Only Mark can edit/delete case creditor status      -->
              <a *ngIf="action === 'Edit'" [routerLink]="[element.id, 'edit']">{{"SHARED.edit" | translate}}</a>
              <a *ngIf="action === 'Delete'" (click)="openDeleteDialog(element.id, $event)">
                {{"SHARED.delete" | translate}}
              </a>
            </p>
          </div>
        </td>
      </ng-container>
    </table>

    <div *ngIf="isLoading" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">{{"SHARED.loading" | translate}}...</span>
      </div>
    </div>

    <mat-paginator
      [length]="paginatorConfig.length"
      [pageSize]="paginatorConfig.pageSize"
      [pageSizeOptions]="[5, 10, 20]"
      (page)="paginatorChange($event)"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
