import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { Creditor } from '../../../../../../_base-shared/models/Entity/Creditor';
import { CreditorReassignComponent } from '../creditor-editor/creditor-reassign/creditor-reassign.component';
import { CreditorService } from '../creditor.service';

@Component({
  selector:    'app-creditor-list',
  templateUrl: './creditor-list.component.html',
  styleUrls:   ['./creditor-list.component.scss'],
})
export class CreditorListComponent implements OnInit {

  public creditors: Creditor[] = [];
  displayedColumns: string[]   = [
    'id',
    'name',
    'active',
    'approved',
    'email',
    'phone_1',
    'phone_2',
    'address',
    'contact_1',
    'contact_2',
    'company_name',
    'actions',
  ];
  actions: string[]            = ['Edit', 'Delete'];
  public isLoading             = 0;
  public dataSource: MatTableDataSource<Creditor>;
  public paginatorConfig       = {
    pageIndex: 0,
    pageSize:  10,
    length:    1,
  };
  public search                = new UntypedFormControl('');
  public searchFocus           = false;

  constructor(
      public dialog: MatDialog,
      private creditorService: CreditorService,
      private toastr: ToastrService,
      private translate: TranslateService,
  ) {
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit(): void {
    this.getCreditors();
    this.search.valueChanges
        .pipe(
            debounceTime(300),
            distinctUntilChanged(),
        )
        .subscribe(() => this.getCreditors());
  }

  getCreditors() {
    this.dataSource = new MatTableDataSource<Creditor>([]);
    this.isLoading++;
    const data = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1,
      search:   this.search.value,
    };

    this.creditorService.index(data).pipe(finalize(() => this.isLoading--)).subscribe(res => {
      this.creditors              = res.data;
      this.dataSource             = new MatTableDataSource<Creditor>(res.data);
      this.dataSource.sort        = this.sort;
      this.paginatorConfig.length = res.meta.total;
    });
  }

  public openDeleteDialog(creditor: Creditor, $event): void {
    $event.stopPropagation();
    $event.preventDefault();
    const dialogRef = this.dialog.open(CreditorReassignComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '500px',
      data:      {
        deleteCreditor: creditor,
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getCreditors();
      }
    });
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.getCreditors();
  }

  onChange() {
    this.getCreditors();
  }

  toggleFocus(setFocus: boolean) {
    this.searchFocus = setFocus;
  }

}
