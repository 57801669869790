<form *ngIf="form" [formGroup]="form" #ngForm>
  <div class="row" *ngIf="type === 'case' || type === 'customer_contact' || type === 'affiliate_case'">
    <div class="col-8">
      <!-- Start Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.start-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="startDate" formControlName="start_date"
               (dateChange)="dateChanged($event, 'end_date', false)">
        <button *ngIf="form.get('start_date') && form.get('start_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('start_date').disabled" (click)="clearFormControl($event, 'start_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate color="primary"></mat-datepicker>
      </mat-form-field>
      <!-- End Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="endDate" formControlName="end_date"
               (dateChange)="dateChanged($event, 'end_date', true)">
        <button *ngIf="form.get('end_date') && form.get('end_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('end_date').disabled" (click)="clearFormControl($event, 'end_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate color="primary"></mat-datepicker>
      </mat-form-field>
      <!-- From/To Shortcut modifiers -->
      <div class="d-inline-block pl-5">
        <mat-radio-group [formControl]="dateRadioControl" aria-label="Select an option"
                         (change)="dateModifierChange($event)">
          <mat-radio-button value="day">{{ "CASES.single.today" | translate }}</mat-radio-button>
          <mat-radio-button value="week">{{ "CASES.single.this-week" | translate }}</mat-radio-button>
          <mat-radio-button value="month">{{ "CASES.single.this-month" | translate }}</mat-radio-button>
        </mat-radio-group>
        <mat-slide-toggle formControlName="only_related" color="primary" class="ml-5"
                          (ngModelChange)="onlyRelatedChange($event)">
          {{ 'CASES.list.filters.only_related.label' | translate }}
        </mat-slide-toggle>
      </div>
    </div>
    <!-- Search -->
    <div class="col-4">
      <mat-form-field appearance="standard" class="input-full-width">
        <mat-label>{{ 'SHARED.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput formControlName="search" type="text">
        <div *ngIf="form.get('search').errors && (form.get('search').touched || ngForm.submitted)">
          <mat-error [hidden]="!form.get('name').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="type === 'case' || type === 'customer_contact' || type === 'affiliate_case'">
    <!-- Status Date Type -->
    <div class="pl-3">
      <mat-label style="padding-right: 10px; display: block">
        {{ 'CASES.list.filters.status_date_type.label' | translate }}
      </mat-label>
      <mat-radio-group formControlName="status_date_type" aria-label="Select an option">
        <mat-radio-button value="sign_up">
          {{ "CASES.list.filters.status_date_type.options.sign_up" | translate }}
        </mat-radio-button>
        <mat-radio-button value="change_date"
                          [matTooltip]="'CASES.list.filters.status_date_type.option-labels.sign_up' | translate">
          {{ "CASES.list.filters.status_date_type.options.change_date" | translate }}
        </mat-radio-button>
        <mat-radio-button value="case_status_change_date"
                          [matTooltip]="'CASES.list.filters.status_date_type.option-labels.sign_up' | translate">
          {{ "CASES.list.filters.status_date_type.options.status_change_date" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <!-- Activity Status -->
    <div class="pl-5">
      <mat-label style="padding-right: 10px; display: block"
                 [matTooltip]="'CASES.list.filters.activity_status.label-tooltip' | translate">
        {{ 'CASES.list.filters.activity_status.label' | translate }}
      </mat-label>
      <mat-radio-group formControlName="activity_status" aria-label="Select an option">
        <mat-radio-button value="active">
          {{ "CASES.list.filters.activity_status.options.active" | translate }}
        </mat-radio-button>
        <mat-radio-button value="inactive">
          {{ "CASES.list.filters.activity_status.options.inactive" | translate }}
        </mat-radio-button>
        <mat-radio-button value="all">
          {{ "CASES.list.filters.activity_status.options.all" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <button style="min-width: 120px" mat-button color="primary" class="btn ml-4" type="button"
            [matMenuTriggerFor]="selectAllMenu">
      <mat-icon style="font-size: 17px;margin-top: 5px;">keyboard_arrow_left</mat-icon>
      Select All
    </button>
    <mat-menu #selectAllMenu="matMenu" xPosition="before" class="selectAllMenu">
      <a mat-menu-item class="dropdown-item" (click)="selectStatuses(true)">
        {{ "CASES.list.filters.status_preset.active" | translate }}
      </a>
      <a mat-menu-item class="dropdown-item" (click)="selectStatuses(false)">
        {{ "CASES.list.filters.status_preset.inactive" | translate }}
      </a>
    </mat-menu>
  </div>
  <!-- Only show on `legal` tab -->
  <div class="row" *ngIf="type === 'legal'">
    <div class="col-8">
      <div class="">
        <mat-label style="padding-right: 10px; display: block"
                   [matTooltip]="'CASES.list.filters.activity_status.label-tooltip' | translate">
          {{ 'CASES.list.filters.activity_status.label' | translate }}
        </mat-label>
        <mat-radio-group formControlName="activity_status" aria-label="Select an option">
          <mat-radio-button value="active">
            {{ "CASES.list.filters.activity_status.options.active" | translate }}
          </mat-radio-button>
          <mat-radio-button value="inactive">
            {{ "CASES.list.filters.activity_status.options.inactive" | translate }}
          </mat-radio-button>
          <mat-radio-button value="all">
            {{ "CASES.list.filters.activity_status.options.all" | translate }}
          </mat-radio-button>
        </mat-radio-group>
        <!--      <mat-slide-toggle formControlName="all_active" color="primary" class="ml-5">-->
        <!--        {{"PAYMENTS.show-all-active" | translate}}-->
        <!--      </mat-slide-toggle>-->
      </div>
    </div>
    <div class="col-4">
      <mat-form-field appearance="standard" class="input-full-width">
        <mat-label>{{ 'SHARED.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput formControlName="search" type="text">
        <div *ngIf="form.get('search').errors && (form.get('search').touched || ngForm.submitted)">
          <mat-error [hidden]="!form.get('name').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </mat-form-field>
    </div>
  </div>
  <!-- Advanced Filters -->
  <div class="filters pb-5 pt-3">
    <div class="advanced-filters">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="d-flex align-items-center justify-content-between" style="width: 100%">
                {{ "PAYMENTS.advanced-filters" | translate }}
                <!--<button mat-stroked-button color="primary" (click)="clearFilters()">-->
                <!--  {{"SHARED.reset-filters" | translate}}-->
                <!--</button>-->
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" *ngIf="type === 'case' || type === 'customer_contact' || type === 'affiliate_case'">
            <!-- Case Creditor Product -->
            <div class="col-lg">
              <app-input type="select" formControlName="case_creditor_products" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'CASES.single.claim-type' | translate"
                         [selectOptions]="caseCreditorProducts" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
            <!-- Case creditor status -->
            <div class="col-lg">
              <app-input type="select" formControlName="case_creditor_statuses" appearance="standard" [searchable]="true"
                         [label]="'CASES.list.filters.claim_status.label' | translate" [multiple]="true" [selectAll]="true" [fullWidth]="true" [selectAll]="true"
                         [selectOptions]="debtStatusOptions" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
            <!-- Status Categories -->
            <div class="col-lg">
              <app-input *ngIf="statusCategories?.length" type="select" [formControl]="statusCategoryControl"
                         appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
                         [label]="'CASES.single.general.status_editor.status_category' | translate"
                         [selectOptions]="statusCategories" [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                         (ngModelChange)="updateSelectedStatusCategories($event)"
                         [showClear]="false" [fullWidth]="true">
              </app-input>
            </div>
            <!-- Statuses -->
            <div class="col-lg">
              <app-input *ngIf="filteredStatusCategories?.length" type="select" [formControlName]="statusFormControlName"
                         appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
                         [label]="'CASES.single.general.status_editor.status' | translate"
                         [selectOptions]="filteredStatusCategories" [optGroupProperty]="'statuses'" [optGroupLabel]="'name'"
                         [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                         [showClear]="false" [fullWidth]="true">
              </app-input>
            </div>
            <!-- Payment Statuses -->
            <div class="col-lg">
              <app-input type="select" formControlName="payment_statuses" appearance="standard"
                         [fullWidth]="true" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'CASES.single.general.status_editor.payment_status' | translate"
                         [selectOptions]="paymentStatuses" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
            <!-- Has distribution -->
            <div *ngIf="userIsAMami()" class="col-lg">
              <app-input type="select" formControlName="case_distribution_status" appearance="standard"
                         [fullWidth]="true"
                         [label]="'CASES.list.filters.has_distribution.label' | translate" [showClear]="true"
                         [selectOptions]="hasDistributionOptions" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <!-- Batch ID -->
            <div *ngIf="userIsAMami()" class="col-lg">
              <app-input type="select" formControlName="distribution_batch_ids" appearance="standard" multiple
                         fullWidth="false"
                         [label]="'DISTRIBUTION.batch.selector.distribution_batch_id' | translate"
                         [selectOptions]="distributionBatches" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
          </div>
          <div class="row">
            <!-- Affiliates -->
            <div class="col-6 col-lg">
              <app-input type="select" formControlName="affiliates" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'CASES.affiliates' | translate"
                         [selectOptions]="affiliates" [selectLabel]="'name'" [selectValue]="'id'" [searchable]="true">
              </app-input>
            </div>
            <!-- Creditors -->
            <div class="col-6 col-lg">
              <app-input type="select" formControlName="creditors" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'CASES.single.creditors.heading' | translate" [searchable]="true"
                         [selectOptions]="creditors" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
            <!-- Amount Paid -->
            <!-- <div class="col-6 col-lg" *ngIf="type === 'case' || type === 'customer_contact'">
              <app-input type="number" formControlName="amount_paid" [label]="'Amount Paid'" appearance="standard"
                         [fullWidth]="true">
              </app-input>
            </div> -->
            <!-- Outstanding Balance-->
            <div class="col-6 col-lg" *ngIf="type === 'case'">
              <app-input type="number" formControlName="outstanding_balance" [label]="'Outstanding Balance'"
                         appearance="standard" [fullWidth]="true">
              </app-input>
            </div>
            <!-- Time since extrajudicial sent-->
            <div class="col-6 col-lg" *ngIf="type === 'case'">
              <app-input type="number" formControlName="days_in_claim_sent_status" [label]="'CASES.list.filters.days_in_claim_sent_status.label' | translate"
                         appearance="standard" [fullWidth]="true">
              </app-input>
            </div>
            <!-- Call Statuses -->
            <!-- <div class="col-6 col-lg">
              <app-input type="select" formControlName="call_statuses" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'CONFIG.call-status.header' | translate"
                         [selectOptions]="callStatuses" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div> -->

            <div class="col-6  col-lg" *ngIf="type === 'customer_contact'">
              <app-input type="number" formControlName="days_no_contact"
                         [label]="'CASES.list.filters.days_no_contact.label' | translate"
                         [appearance]="'standard'" [extraLabel]="false">
              </app-input>
            </div>
            <div class="col-6  col-lg" *ngIf="type === 'customer_contact'">
              <app-input type="select" formControlName="days_no_contact_channels" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'CASES.list.filters.days_no_contact_channels.label' | translate"
                         [selectOptions]="notificationChannels" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <!-- User Department Assignments -->
            <!-- <div class="col-6 col-lg" *ngIf="type === 'case' || type === 'customer_contact'">
              <app-input type="select" *ngIf="form" formControlName="user_department_assignments" appearance="standard"
                         [label]="'CASES.list.filters.user_case_roles.label' | translate" multiple [fullWidth]="true"
                         [selectOptions]="departmentCategories" [selectLabel]="'name'" [selectValue]="'id'"
                         [optGroupProperty]="'departments'">
              </app-input>
            </div> -->
            <!-- Affiliate Users-->
            <!-- <div class="col-6 col-lg">
              <app-input type="select" formControlName="affiliate_user_ids" appearance="standard"
                         [label]="'AFFILIATES.affiliate_users' | translate" multiple [fullWidth]="true"
                         [selectOptions]="affiliateUsers" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div> -->
          </div>
          <!-- Department Users -->
          <div class="row">
            <ng-container *ngFor="let departmentCategory of departmentCategories">
              <ng-container *ngFor="let department of departmentCategory.departments">
                <div class="col-6 col-md-4 col-lg-3 col-xl-3" *ngIf="department.active">
                  <app-input type="select" *ngIf="form" [formControlName]="department.type" appearance="standard"
                             [label]="department.name" multiple [fullWidth]="true" searchable="true"
                             [selectOptions]="department.users" [selectLabel]="'name'" [selectValue]="'id'">
                  </app-input>
                </div>
              </ng-container>
            </ng-container>
            <!-- Packagers
            <div class="col-lg-3" *ngIf="authUser.packager.master">
              <app-input type="select" appearance="standard" [label]="'PACKAGER.model_name.singular' | translate"
                         formControlName="packagers" [showClear]="false" [showLabel]="true" [fullWidth]="true"
                         [selectOptions]="packagers" [multiple]="true" [selectLabel]="['name_en']" [selectValue]="'id'">
              </app-input>
            </div>
            Packagers Statuses
            <div class="col-lg-3" *ngIf="!authUser.packager.master">
              <app-input type="select" appearance="standard" [label]="'CASES.packager-status' | translate"
                         formControlName="packager_statuses" [showClear]="false" [showLabel]="true" [fullWidth]="true"
                         [selectOptions]="packagerStatuses" [multiple]="true" [selectLabel]="['name_en']" [selectValue]="'id'">
              </app-input>
            </div> -->
          </div>
          <!-- Case Entities -->
          <div class="row" *ngIf="type === 'legal'">
            <!-- Notaries -->
            <div class="col-6 col-lg">
              <app-input type="select" formControlName="days_no_contact_channels" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'LEGAL.notary' | translate"
                         [selectOptions]="notaries" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
            <!-- Administrators -->
            <div class="col-6 col-lg">
              <app-input type="select" formControlName="administrators" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'LEGAL.administrator' | translate"
                         [selectOptions]="administrators" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
            <!-- Courts -->
            <div class="col-6 col-lg">
              <app-input type="select" formControlName="courts" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'LEGAL.court' | translate"
                         [selectOptions]="courts" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
            <!-- Solicitors -->
            <div class="col-6 col-lg">
              <app-input type="select" formControlName="solicitors" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'LEGAL.solicitor' | translate"
                         [selectOptions]="solicitors" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
          </div>
          <div class="row" *ngIf="type === 'legal'">
            <div class="col-6 col-lg">
              <mat-form-field color="primary" appearance="standard" class="input-full-width">
                <mat-label>{{'ADDRESS.city' | translate}}</mat-label>
                <input type="text" matInput formControlName="city">
              </mat-form-field>
            </div>
            <div class="col-6 col-lg">
              <mat-form-field color="primary" appearance="standard" class="input-full-width">
                <mat-label>{{ 'ADDRESS.region' | translate }}</mat-label>
                <input type="text" matInput formControlName="region">
              </mat-form-field>
            </div>
            <div class="col-6 col-lg">
              <mat-form-field color="primary" appearance="standard" class="input-full-width">
                <mat-label>{{'CASES.list.filters.notary_appointed.label' | translate}}</mat-label>
                <mat-select formControlName="notary_appointed">
                  <mat-option [value]="1">{{'SHARED.yes' | translate}}</mat-option>
                  <mat-option [value]="0">{{'SHARED.no' | translate}}</mat-option>
                </mat-select>
                <button *ngIf="form.get('notary_appointed') &&
                  (form.get('notary_appointed').value === 0 || form.get('notary_appointed').value === 1)"
                        mat-button matSuffix mat-icon-button
                        [disabled]="form.get('notary_appointed').disabled" aria-label="Clear"
                        (click)="clearFormControl($event, 'notary_appointed')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="type === 'case'">
            <div class="col-6 col-md-4 col-lg-3 col-xl-3">
              <mat-slide-toggle formControlName="has_property" color="primary" class="ml-5">
                {{ 'CASES.list.filters.has_property.label' | translate }}
              </mat-slide-toggle>
            </div>
            <div class="col-6 col-md-4 col-lg-3 col-xl-3">
              <mat-slide-toggle formControlName="has_pending_docs" color="primary" class="ml-5">
                {{ 'CASES.list.filters.has_pending_docs.label' | translate }}
              </mat-slide-toggle>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</form>
